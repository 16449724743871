.content {
  height: 100vh;
  display: grid;
  align-items: start;
}

@media (min-width: 1024px) {
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
