* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

body {
  height: 100vh;
  width: 100%;
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    ),
    url("./image/fondoPantalla/hero_1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
