.boxCanvas3 {
  margin: 10px 10px;
  background-color: white;
  border-radius: 6px;
}

.canvas3 {
  border-radius: 6px 6px 0 0;
  width: 100%;
  height: 450px;
  border-bottom: 1px solid #002391;
}

.boxButton63 {
  display: flex;
  justify-content: center;
  margin: 20px 0px 20px 3px;
}

.Button63 {
  width: 45%;
  height: 45px;
  border: 1px solid #002391;
  color: #002391;
  font-weight: bold;
  font-size: 1rem;
  font-family: sans-serif;
  background-color: white;
  border-radius: 5px;
  margin: 0 10px;
}

@media (min-width: 768px) {
  .Button63:hover {
    background-color: #002391;
    color: white;
  }
}
