.cardForm3 {
  width: 97%;
  max-height: 300px;
  margin: auto;
  background-color: white;
  border-radius: 5px;
  padding: 5px;
  padding-bottom: 20px;
}

.errorMesage {
  margin: 0px 20px;
  color: red;
}

.box3 {
  margin: 20px 0px;
}

.boxInput3 {
  margin-top: 20px;
  margin-bottom: 5px;
  width: 98%;
  text-align: center;
  display: flex;
  align-items: flex-end;
}

.inputForm3 {
  margin-top: 10px;
  width: 95%;
  height: 35px;
  font-size: 1rem;
  border: none;
  border-bottom: 1px solid #b4b4b4;
  color: #b4b4b4;
  font-family: sans-serif;
}

.inputForm3::placeholder {
  color: #b4b4b4;
}

.boxInputi3 .inputForm3:focus {
  border-bottom: 1px solid #002391;
}

@media (min-width: 768px) {
  .cardForm3 {
    max-width: 600px;
    max-height: 500px;
    margin: auto;
  }

  .box3 {
    margin: 20px 20px;
  }

  .inputForm3 {
    height: 60px;
    font-size: 1.5em;
  }

  .Buttonjunio3:hover {
    background-color: #002391;
    color: white;
  }
}

@media (min-width: 1024px) {
  .cardForm3 {
    width: 50%;
  }
}
