.cardForm2 {
  width: 97%;
  max-height: 300px;
  margin: auto;
  background-color: white;
  border-radius: 5px;
  padding: 5px;
  padding-bottom: 20px;
}

.errorMesage {
  margin: 0px 20px;
  color: red;
}

.box {
  margin: 20px 0px;
}

.boxInputi {
  margin-top: 20px;
  margin-bottom: 5px;
  width: 98%;
  text-align: center;
  display: flex;
  align-items: flex-end;
}

.inputForm {
  margin-top: 10px;
  width: 95%;
  height: 35px;
  font-size: 1rem;
  border: none;
  border-bottom: 1px solid #b4b4b4;
  color: #b4b4b4;
  font-family: sans-serif;
}

.inputForm::placeholder {
  color: #b4b4b4;
}

.boxInputi .inputForm:focus {
  border-bottom: 1px solid #015FC9;
}

@media (min-width: 768px) {
  .cardForm2 {
    max-width: 600px;
    max-height: 500px;
    margin: auto;
  }

  .box {
    margin: 20px 20px;
  }

  .inputForm {
    height: 60px;
    font-size: 1.5em;
  }

  .Button:hover {
    background-color: #015FC9;
    color: white;
  }
}

@media (min-width: 1024px) {
  .cardForm2 {
    width: 50%;
  }
}
