.formCard {
  background-color: white;
  border-radius: 6px;
  padding: 6px;
  margin: 10px;
  overflow: auto;
  max-height: 500px;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #015FC9;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  animation: pulse 1s linear infinite;
}
.loader:after {
  content: "";
  position: absolute;
  width: 48px;
  height: 48px;
  border: 5px solid #015FC9;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: scaleUp 1s linear infinite;
}

@keyframes scaleUp {
  0% {
    transform: translate(-50%, -50%) scale(0);
  }
  60%,
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}
@keyframes pulse {
  0%,
  60%,
  100% {
    transform: scale(1);
  }
  80% {
    transform: scale(1.2);
  }
}

.boxInputFlex {
  display: flex;
  align-items: flex-end;
  margin: 10px 0;
}

.boxinput {
  width: 100%;
}

.boxButtonfinal {
  margin: 20px 0px 20px 3px;
  text-align: center;
}

.Buttonfinal {
  width: 45%;
  height: 45px;
  border: 1px solid #000097;
  color: #000097;
  font-weight: bold;
  font-size: 1rem;
  font-family: sans-serif;
  background-color: white;
  border-radius: 5px;
}

@media (min-width: 768px) {
  .Buttonfinal {
    width: 200px;
  }
  .flexheigpc {
    display: flex;
  }
  .boxInputFlex {
    width: 100%;
  }
}

@media (min-width: 1024px) {
  .boxbuttonsflex {
    width: 50%;
    display: flex;
  }

  .boxButtonfinal {
    margin: 0 20px;
  }

  .formCard {
    padding: 20px 0;
    max-height: none;
  }
}
