.formThree3 {
  margin: auto;
  background-color: white;
  border-radius: 5px;
  padding: 5px;
  padding-bottom: 20px;
  width: 95%;
  max-height: 500px;
  overflow: auto;
}

.inputForm3 {
  margin-top: 10px;
  width: 95%;
  height: 35px;
  font-size: 1rem;
  border: none;
  border-bottom: 1px solid #b4b4b4;
  color: #b4b4b4;
  font-family: sans-serif;
}

.errorMesage {
  margin: 0px 20px;
  color: red;
}

.boxInput3 {
  margin: 20px 0px;
  width: 95%;
  text-align: center;
}

.boxButton3junio {
  margin: 20px 0 0 20px;
}

.boxInput3 {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.Buttonjunio3 {
  width: 45%;
  height: 45px;
  border: 1px solid #000097;
  color: #000097;
  font-weight: bold;
  font-size: 1rem;
  font-family: sans-serif;
  background-color: white;
  border-radius: 5px;
}

.icon3 {
  margin: 0 20px;
  font-size: 2em;
  color: #b4b4b4;
}

@media (min-width: 768px) {
  .formThree3 {
    max-width: 700px;
    max-height: 500px;
  }
  .boxflex3 {
    display: flex;
  }

  .boxinputanderror3 {
    width: 100%;
  }

  .boxInput3 {
    width: 100%;
  }
}

@media (min-width: 1024px) {
  .formThree3 {
    width: 50%;
    max-height: 800px;
    max-width: 800px;
  }
  .formThree3 {
    overflow: initial;
  }

  .boxflex3 {
    display: flex;
  }
}

/***************************FORM 3 CSS******************************************/
.cardForm3 {
  width: 90%;
  margin: auto;
  background-color: white;
  border-radius: 5px;
  padding: 5px;
  padding-bottom: 20px;
}

.boxInput3 {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 98%;
  text-align: center;
  display: flex;
  align-items: flex-end;
}

.errorMesage {
  margin: 0px 20px;
  color: red;
}

.inputForm3 {
  margin-top: 10px;
  width: 95%;
  height: 35px;
  font-size: 1rem;
  border: none;
  border-bottom: 1px solid #b4b4b4;
  color: #b4b4b4;
  font-family: sans-serif;
}

.inputForm3::placeholder {
  color: #b4b4b4;
}

.BoxInfo3 {
  padding: 5px;
  border: 1px solid #b4b4b4;
  border-radius: 5px;
  margin: 5px 5px;
}

.P-Info3 {
  font-size: 1em;
  font-weight: 500;
  font-family: sans-serif;
  display: flex;
  margin: 10px 0px;
}

.GreenText3 {
  font-size: 1em;
  color: #002391;
  font-weight: bolder;
  margin: 0px 10px;
}

.boxButton3 {
  margin-top: 20px;
  margin-left: 3px;
}

@media (min-width: 1024px) {
  .cardForm3 {
    max-width: 600px;
  }

  .BoxInfo3 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
