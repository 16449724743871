.cardform {
  background-color: white;
  padding: 3px;
  border-radius: 6px;
  margin: 0px 5px;
  max-height: 95%;
  overflow: auto;
}

.UploadBundles {
  height: 270px;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  gap: 20px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border: 2px dashed #cacaca;
  background-color: rgba(255, 255, 255, 1);
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0px 48px 35px -48px rgba(0, 0, 0, 0.1);
  margin-left: auto;
  margin-right: auto;
}

.card {
  margin: 0px 10px;
}

.galleriBundles {
  height: 170px;
  border: 2px dashed #cacaca;
  background-color: rgba(255, 255, 255, 1);
  align-items: center;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}

.boxGalery {
  display: flex;
  align-items: center;
  overflow: auto;
  padding: 10px;
}

.boxInputImage {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.galleriBundles li {
  list-style: none;
  border: 1px solid #cacaca;
  padding: 5px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 12px 10px;
  height: 100px;
  justify-content: center;
}

.fileNamePDF {
  font-size: 12px;
  margin: 0px;
  color: #015FC9;
  font-weight: 600;
  margin: 10px 0px 0px 0px;
}

.textinputbundles {
  color: #015FC9;
  font-family: sans-serif;
  font-weight: 500;
  font-size: 1.2em;
}

.tittleimage {
  text-align: center;
  margin: 10px 0px 0px 0px;
  color: #015FC9;
  font-weight: 500;
  font-size: 1em;
}

.iconDeletePDF {
  color: #c12929;
  font-size: 24px;
  margin: 0px;
  padding: 0px;
  position: relative;
  left: 59px;
  top: -13px;
  cursor: pointer;
}

.imageGaleryBundle {
  width: 70px;
  height: 70px;
  position: relative;
  top: -7px;
}

.iconDeletePDF:hover {
  color: red;
}

@media (min-width: 768px) {
  .boxInputImage {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }

  .boxcard {
    width: 50%;
  }

  .cardform {
    width: 800px;
    margin: auto;
    padding: 10px;
  }
}

@media (min-width: 768px) {
  .Button4:hover {
    background-color: #015FC9;
    color: white;
  }
}

@media (min-width: 1024px) {
  .cardform {
    max-height: none;
    max-width: 1000px;
  }
}
