.header {
  width: 100%;
  padding-top: 20px;
}

.boxImage {
  width: 100%;
  text-align: center;
}

.image {
  width: 250px;
}

.boxTittle {
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
}

.Tittle {
  font-family: sans-serif;
  color: #ffffff;
  font-size: 1.7em;
  letter-spacing: 0.5px;
  margin: 20px 10px;
}

.text {
  color: #ffffff;
  font-family: sans-serif;
  font-size: 1em;
  margin: 20px 10px;
  font-weight: 600;
}

@media (min-width: 768px) {
  .image {
    width: 300px;
  }

  .Tittle {
    font-size: 2em;
  }

  .text {
    font-size: 1em;
  }
}

@media (min-width: 1024px) {
  .header {
    width: 50%;
  }
}
