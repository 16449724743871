.scroll3 {
  width: 100%;
  max-height: 280px;
  overflow: auto;
}

.scroll3::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.cardCredit3 {
  text-align: center;
  margin: 15px;
  border-radius: 6px;
  padding: 6px;
  border: 1px solid #002391;
  max-width: 280px;
  margin: auto auto 20px auto;
  background-color: #002391;
  cursor: pointer;
}

.TextCredit3 {
  font-size: 1.09em;
  font-family: sans-serif;
  font-weight: bold;
  margin: 5px 0;
  color: white;
}

@media (min-width: 768px) {
  .cardCredit3 {
    display: flex;
    justify-content: center;
    padding: 16px;
    max-width: 700px;
    margin: auto auto 20px auto;
  }

  .cardCredit3:hover {
    background-color: #0029b1;
  }

  .TextCredit3 {
    font-size: 1.2em;
    margin: 0 30px;
  }
}

@media (min-width: 1024px) {
  .scroll3 {
    width: 50%;
    max-height: 400px;
    padding-right: 10px;
  }
}
