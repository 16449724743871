body.active-modal {
  overflow-y: hidden;
}

.btn-modal {
  padding: 10px 20px;
  display: block;
  margin: 100px auto 0;
  font-size: 18px;
}

.modal,
.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}

.overlay {
  background: rgba(49, 49, 49, 0.8);
}
.modal-content3 {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #f1f1f1;
  padding: 14px 28px;
  border-radius: 3px;
  max-width: 600px;
  min-width: 300px;
}

.TextP3 {
  font-size: 1.2em;
  font-weight: bold;
  color: #00a8f3;
}

.close-modal3 {
  padding: 10px 10px;
  border: 1px solid #00a8f3;
  color: #00a8f3;
  font-weight: bold;
  font-size: 1rem;
  font-family: sans-serif;
  background-color: white;
  border-radius: 5px;
}

.close-modal3:hover {
  background-color: #00a8f3;
  color: white;
}

.boxMessage3 {
  margin: 20px 0px;
}
